//Authentication is handled here, please refer to auth.js in utils for request logic

import {
    MESSAGES_UPDATED,
    MESSAGE_RECEIVED,
    SOCKET_ERROR,
    DESTROY_SOCKET
} from '../utils/consts';
  
  const initialState = {
    messages: [],
    errors: null
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case MESSAGES_UPDATED:
        return {
          ...state,
          messages: payload.chats
        }
      case MESSAGE_RECEIVED:
        return {
          ...state,
          messages: [...state.messages, payload]
        };
      case SOCKET_ERROR:
        return {
          ...state,
          errors:payload
        };
      case DESTROY_SOCKET:
        return {
          messages: [],
          errors: null
        };
      default:
        return state;
    }
  }
  