export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const LOAD_FAIL = 'LOAD_FAIL';
export const LOGOUT = 'LOGOUT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAIL = 'VERIFICATION_FAIL';

export const PING_RECEIVED = 'PING_RECEIVED';
export const ROOM_CREATED = 'ROOM_CREATED';
export const ROOM_JOINED = 'ROOM_JOINED';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const MESSAGES_UPDATED = 'MESSAGES_UPDATED';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const DESTROY_SOCKET = 'DESTROY_SOCKET';