import { combineReducers } from 'redux';
import alert from './alert';
import register from './register';
import sockets from './sockets';

export default combineReducers({
  alert,
  register,
  sockets
});
